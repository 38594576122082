<template>
  <div>
    <v-row>
      <!-- Habilitacoes -->
      <v-col cols="12">
        <v-select
          v-model="profissionais.habilitacoes"
          :items="habilitacoes"
          label="Habilitações"
          hide-details
          persistent-hint
          multiple
          outlined
          dense
        ></v-select>
      </v-col>
      <!-- Viagem -->
      <v-col cols="12" sm="12" md="6">
        <v-checkbox
          v-model="profissionais.viagem"
          true-value="1"
          false-value="0"
          hide-details
          :label="`Disponibilidade para viagem? ${
            profissionais.viagem == '0' ? 'Não' : 'Sim'
          }`"
        ></v-checkbox>
      </v-col>
      <!-- Mudanca -->
      <v-col cols="12" sm="12" md="6">
        <v-checkbox
          v-model="profissionais.mudanca"
          true-value="1"
          false-value="0"
          hide-details
          :label="`Disponibilidade para mudança? ${
            profissionais.mudanca == '0' ? 'Não' : 'Sim'
          }`"
        ></v-checkbox>
      </v-col>
      <!-- Qualificacoes -->
      <v-col cols="12">
        <v-textarea
          v-model="profissionais.qualificacoes"
          label="Qualificações"
          placeholder="Digite suas qualificações."
          outlined
          dense
          counter="65500"
        ></v-textarea>
      </v-col>
    </v-row>
    <!-- Idiomas -->
    <v-row class="mt-6">
      <v-col cols="12">
        <IdiomasRepeater />
      </v-col>
    </v-row>
    <!-- Redes Sociais -->
    <v-row class="mt-6">
      <v-col cols="12">
        <RedesSociais />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-end">
          <v-btn :loading="loadingButtonSave" color="primary" @click="save()">
            Salvar
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import IdiomasRepeater from "./components/IdiomasRepeater.vue";
import RedesSociais from "./components/RedesSociais.vue";

export default {
  name: "InformacoesProfissionaisEdit",
  inject: ["$validator"],
  components: {
    IdiomasRepeater,
    RedesSociais,
  },
  data() {
    return {
      habilitacoes: ["A", "B", "C", "D", "E"],

      loadingButtonSave: false,
      item: {},
    };
  },
  methods: {
    save() {
      this.$validator
        .validate("res-candidato-profissionais.*")
        .then((result) => {
          if (result) {
            this.loadingButtonSave = true;

            Object.assign(this.item, this.profissionais);

            const data = {
              uuid: this.candidato.uuid,
              data: this.item,
            };

            this.$store
              .dispatch("resCandidatos/salvarDadosProfissionaisCandidato", data)
              .then((response) => {
                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  cor: "green",
                  mensagem:
                    "Informações profissionais atualizadas com sucesso.",
                  duracao: 3000,
                });

                this.changeDataCandidato();
              })
              .catch(() => {
                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  cor: "red",
                  mensagem:
                    "Ocorreu um erro ao atualizar informações profissionais.",
                  duracao: 3000,
                });
              })
              .finally(() => {
                this.loadingButtonSave = false;
                this.item = {};
              });
          }
        });
    },

    changeDataCandidato() {
      this.candidato.habilitacao = this.item.habilitacoes;

      return this;
    },
  },
  computed: {
    profissionais: {
      get() {
        return this.$store.getters["resCandidatos/getProfissionais"];
      },
      set(value) {
        this.$store.dispatch("resCandidatos/setProfissionais", value);
      },
    },
    candidato: {
      get() {
        return this.$store.getters["resCandidatos/getCandidato"];
      },
      set(value) {
        this.$store.dispatch("resCandidatos/setCandidato", value);
      },
    },
  },
};
</script>

<style></style>
