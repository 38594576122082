import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-column flex-sm-row justify-space-between align-center"},[_c('h2',[_vm._v("Idiomas")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.add()}}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" Adicionar ")],1)],1)]),_vm._l((_vm.idiomas),function(idioma,i){return _c(VCol,{key:i,attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-column flex-md-row align-center my-2"},[(_vm.isEdit == i)?_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"6"}},[_c(VTextField,{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],ref:("descricao-" + i),refInFor:true,attrs:{"label":"Idioma","placeholder":"Digite o idioma.","outlined":"","dense":"","data-vv-as":"idioma","persistent-hint":"","hint":_vm.errors.first(("res-candidato-profissionais.descricao-" + i)),"error":_vm.errors.collect(("res-candidato-profissionais.descricao-" + i))
                .length
                ? true
                : false,"hide-details":_vm.errors.collect(("res-candidato-profissionais.descricao-" + i))
                .length
                ? false
                : true,"data-vv-validate-on":"change","data-vv-scope":"res-candidato-profissionais","id":("descricao-" + i),"name":("descricao-" + i)},model:{value:(_vm.item.descricao),callback:function ($$v) {_vm.$set(_vm.item, "descricao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.descricao"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"6"}},[_c(VTextField,{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],ref:("experiencia-" + i),refInFor:true,attrs:{"label":"Experiência","placeholder":"Digite o número de meses ou anos que tem de experiência.","outlined":"","dense":"","data-vv-as":"experiência","persistent-hint":"","hint":_vm.errors.first(("res-candidato-profissionais.experiencia-" + i)),"error":_vm.errors.collect(("res-candidato-profissionais.experiencia-" + i))
                .length
                ? true
                : false,"hide-details":_vm.errors.collect(("res-candidato-profissionais.experiencia-" + i))
                .length
                ? false
                : true,"data-vv-validate-on":"change","data-vv-scope":"res-candidato-profissionais","id":("experiencia-" + i),"name":("experiencia-" + i),"type":"number"},model:{value:(_vm.item.experiencia),callback:function ($$v) {_vm.$set(_vm.item, "experiencia", $$v)},expression:"item.experiencia"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"6"}},[_c(VSelect,{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"items":_vm.tempos,"item-text":"text","item-value":"value","label":"Tempo","placeholder":"Selecione seu tempo de experiência","outlined":"","dense":"","data-vv-as":"tempo","persistent-hint":"","hint":_vm.errors.first(("res-candidato-profissionais.tempo-" + i)),"error":_vm.errors.collect(("res-candidato-profissionais.tempo-" + i)).length
                ? true
                : false,"hide-details":_vm.errors.collect(("res-candidato-profissionais.tempo-" + i)).length
                ? false
                : true,"data-vv-validate-on":"change","data-vv-scope":"res-candidato-profissionais","id":("tempo-" + i),"name":("tempo-" + i)},model:{value:(_vm.item.tempo),callback:function ($$v) {_vm.$set(_vm.item, "tempo", $$v)},expression:"item.tempo"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"6"}},[_c(VSelect,{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"items":_vm.proficiencias,"label":"Proficiência","placeholder":"Selecione sua proficiência","outlined":"","dense":"","data-vv-as":"proficiência","persistent-hint":"","hint":_vm.errors.first(("res-candidato-profissionais.proficiencia-" + i)),"error":_vm.errors.collect(("res-candidato-profissionais.proficiencia-" + i))
                .length
                ? true
                : false,"hide-details":_vm.errors.collect(("res-candidato-profissionais.proficiencia-" + i))
                .length
                ? false
                : true,"data-vv-validate-on":"change","data-vv-scope":"res-candidato-profissionais","id":("proficiencia-" + i),"name":("proficiencia-" + i)},model:{value:(_vm.item.proficiencia),callback:function ($$v) {_vm.$set(_vm.item, "proficiencia", $$v)},expression:"item.proficiencia"}})],1)],1):_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"12"}},[_c(VList,{attrs:{"three-line":""}},[_c(VListItemTitle,{staticClass:"font-weight-medium text-h6"},[_vm._v(" "+_vm._s(idioma.descricao)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(idioma.experiencia)+" "+_vm._s(idioma.tempo == "M" ? idioma.experiencia > 1 ? "Meses" : "Mês" : idioma.experiencia > 1 ? "Anos" : "Ano")+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.proficienciasView[idioma.proficiencia])+" ")])],1)],1)],1),_c(VBtn,{staticClass:"ml-2 mt-md-0 mt-2",attrs:{"small":"","loading":_vm.loadingSave && _vm.loadingSave == i ? true : false,"disabled":_vm.isEdit == null ? false : _vm.isEdit == i ? false : true,"min-width":"1em","color":"info"},on:{"click":function($event){return _vm.save(idioma, i)}}},[_c(VIcon,[_vm._v(_vm._s(_vm.isEdit == i ? "mdi-check" : "mdi-pencil"))])],1),_c(VBtn,{staticClass:"ml-2 mt-md-0 mt-2",attrs:{"small":"","loading":_vm.loadingRemove && _vm.loadingRemove == i ? true : false,"disabled":_vm.isEdit == null ? false : _vm.isEdit == i ? false : true,"min-width":"1em","color":"error"},on:{"click":function($event){return _vm.remove(idioma, i)}}},[_c(VIcon,[_vm._v(_vm._s(_vm.isEdit == i ? "mdi-close" : "mdi-delete"))])],1)],1)])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }