import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VSelect,{attrs:{"items":_vm.habilitacoes,"label":"Habilitações","hide-details":"","persistent-hint":"","multiple":"","outlined":"","dense":""},model:{value:(_vm.profissionais.habilitacoes),callback:function ($$v) {_vm.$set(_vm.profissionais, "habilitacoes", $$v)},expression:"profissionais.habilitacoes"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"6"}},[_c(VCheckbox,{attrs:{"true-value":"1","false-value":"0","hide-details":"","label":("Disponibilidade para viagem? " + (_vm.profissionais.viagem == '0' ? 'Não' : 'Sim'))},model:{value:(_vm.profissionais.viagem),callback:function ($$v) {_vm.$set(_vm.profissionais, "viagem", $$v)},expression:"profissionais.viagem"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"12","md":"6"}},[_c(VCheckbox,{attrs:{"true-value":"1","false-value":"0","hide-details":"","label":("Disponibilidade para mudança? " + (_vm.profissionais.mudanca == '0' ? 'Não' : 'Sim'))},model:{value:(_vm.profissionais.mudanca),callback:function ($$v) {_vm.$set(_vm.profissionais, "mudanca", $$v)},expression:"profissionais.mudanca"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"label":"Qualificações","placeholder":"Digite suas qualificações.","outlined":"","dense":"","counter":"65500"},model:{value:(_vm.profissionais.qualificacoes),callback:function ($$v) {_vm.$set(_vm.profissionais, "qualificacoes", $$v)},expression:"profissionais.qualificacoes"}})],1)],1),_c(VRow,{staticClass:"mt-6"},[_c(VCol,{attrs:{"cols":"12"}},[_c('IdiomasRepeater')],1)],1),_c(VRow,{staticClass:"mt-6"},[_c(VCol,{attrs:{"cols":"12"}},[_c('RedesSociais')],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-end"},[_c(VBtn,{attrs:{"loading":_vm.loadingButtonSave,"color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Salvar ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }