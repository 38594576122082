import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-column flex-sm-row justify-start align-center"},[_c('h2',[_vm._v("Redes Sociais")])])]),_vm._l((_vm.redesSociais),function(redeSocial,i){return _c(VCol,{key:i,attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-column flex-md-row align-center my-2"},[(_vm.isEdit == i)?_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('h3',{staticClass:"font-weight-medium text-h6"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.item.rede))+" ")]),_c(VTextField,{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],ref:("link-" + i),refInFor:true,attrs:{"label":"Link","placeholder":"Cole o link do seu perfil na rede social escolhida.","outlined":"","dense":"","data-vv-as":"link","persistent-hint":"","hint":_vm.errors.first(("res-candidato-profissionais.link-" + i)),"error":_vm.errors.collect(("res-candidato-profissionais.link-" + i)).length
                ? true
                : false,"hide-details":_vm.errors.collect(("res-candidato-profissionais.link-" + i)).length
                ? false
                : true,"data-vv-validate-on":"change","data-vv-scope":"res-candidato-profissionais","id":("link-" + i),"name":("link-" + i)},model:{value:(_vm.item.link),callback:function ($$v) {_vm.$set(_vm.item, "link", $$v)},expression:"item.link"}})],1)],1):_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"12"}},[_c(VList,{attrs:{"three-line":""}},[(redeSocial.link == null)?_c(VListItemTitle,[_c(VChip,{attrs:{"small":"","color":"warning"}},[_vm._v(" Não informado ")])],1):_vm._e(),_c(VListItemTitle,{staticClass:"font-weight-medium text-h6 text-center text-md-left"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(redeSocial.rede))+" ")]),_c(VListItemSubtitle,[_c(VTooltip,{attrs:{"color":redeSocial.link ? 'primary' : 'warning',"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"tag":"a","href":redeSocial.link,"target":"_blank","link":"","text":"","color":"accent"}},'v-btn',attrs,false),on),[_vm._v(" Link ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(redeSocial.link ? redeSocial.link : "Nenhum link disponível")+" ")])])],1)],1)],1)],1),_c(VBtn,{staticClass:"ml-2 mt-md-0 mt-2",attrs:{"small":"","loading":_vm.loadingSave && _vm.loadingSave == i ? true : false,"disabled":_vm.isEdit == null ? false : _vm.isEdit == i ? false : true,"min-width":"1em","color":"info"},on:{"click":function($event){return _vm.save(redeSocial, i)}}},[_c(VIcon,[_vm._v(_vm._s(_vm.isEdit == i ? "mdi-check" : "mdi-pencil"))])],1),_c(VBtn,{staticClass:"ml-2 mt-md-0 mt-2",attrs:{"small":"","loading":_vm.loadingRemove && _vm.loadingRemove == i ? true : false,"disabled":_vm.validateDisabledButtonRemove(redeSocial, i),"min-width":"1em","color":"error"},on:{"click":function($event){return _vm.remove(redeSocial, i)}}},[_c(VIcon,[_vm._v(_vm._s(_vm.isEdit == i ? "mdi-close" : "mdi-delete"))])],1)],1)])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }