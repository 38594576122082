<template>
  <v-row>
    <v-col cols="12">
      <div class="d-flex flex-column flex-sm-row justify-start align-center">
        <h2>Redes Sociais</h2>
      </div>
    </v-col>

    <v-col cols="12" v-for="(redeSocial, i) in redesSociais" :key="i">
      <div class="d-flex flex-column flex-md-row align-center my-2">
        <v-row v-if="isEdit == i">
          <!-- link -->
          <v-col cols="12">
            <h3 class="font-weight-medium text-h6">
              {{ item.rede | capitalize() }}
            </h3>
            <v-text-field
              v-model="item.link"
              label="Link"
              placeholder="Cole o link do seu perfil na rede social escolhida."
              outlined
              dense
              data-vv-as="link"
              persistent-hint
              :hint="errors.first(`res-candidato-profissionais.link-${i}`)"
              :error="
                errors.collect(`res-candidato-profissionais.link-${i}`).length
                  ? true
                  : false
              "
              :hide-details="
                errors.collect(`res-candidato-profissionais.link-${i}`).length
                  ? false
                  : true
              "
              v-validate="'required'"
              data-vv-validate-on="change"
              data-vv-scope="res-candidato-profissionais"
              :ref="`link-${i}`"
              :id="`link-${i}`"
              :name="`link-${i}`"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" sm="12" md="12">
            <v-list three-line>
              <v-list-item-title v-if="redeSocial.link == null">
                <v-chip small color="warning"> Não informado </v-chip>
              </v-list-item-title>
              <v-list-item-title
                class="font-weight-medium text-h6 text-center text-md-left"
              >
                {{ redeSocial.rede | capitalize() }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-tooltip
                  :color="redeSocial.link ? 'primary' : 'warning'"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      tag="a"
                      :href="redeSocial.link"
                      target="_blank"
                      link
                      text
                      color="accent"
                    >
                      Link
                    </v-btn>
                  </template>
                  <span>
                    {{
                      redeSocial.link
                        ? redeSocial.link
                        : "Nenhum link disponível"
                    }}
                  </span>
                </v-tooltip>
              </v-list-item-subtitle>
            </v-list>
          </v-col>
        </v-row>
        <v-btn
          small
          :loading="loadingSave && loadingSave == i ? true : false"
          :disabled="isEdit == null ? false : isEdit == i ? false : true"
          min-width="1em"
          class="ml-2 mt-md-0 mt-2"
          color="info"
          @click="save(redeSocial, i)"
        >
          <v-icon>{{ isEdit == i ? "mdi-check" : "mdi-pencil" }}</v-icon>
        </v-btn>
        <v-btn
          small
          :loading="loadingRemove && loadingRemove == i ? true : false"
          :disabled="validateDisabledButtonRemove(redeSocial, i)"
          min-width="1em"
          class="ml-2 mt-md-0 mt-2"
          color="error"
          @click="remove(redeSocial, i)"
        >
          <v-icon>{{ isEdit == i ? "mdi-close" : "mdi-delete" }}</v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "RedesSociais",
  inject: ["$validator"],
  data() {
    return {
      loadingRemove: null,
      loadingSave: null,

      // Define se está no modo de edição ou não
      isEdit: null,
      item: {},
    };
  },
  methods: {
    /**
     * @description Se estiver em modo edição e o usuário desejar cancelar o processo de atualização está função limpará o processo. Caso não esteja em modo de edição o dado será excluído.
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    remove(item = Object, index = Number) {
      if (this.isEdit == index) {
        this.isEdit = null;
        this.item = {};

        return;
      }

      this.loadingRemove = index;

      if (item.id || item.uuid) {
        this.$store
          .dispatch("resCandidatosRedesSociais/remove", item.uuid)
          .then(() => {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              cor: "green",
              mensagem: "Rede social removido com sucesso.",
              duracao: 3000,
            });

            const newData = {
              rede: item.rede,
              link: null,
            };

            const redes = [...this.redesSociais];
            redes[index] = newData;

            this.redesSociais = redes;
          })
          .catch(() => {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              cor: "red",
              mensagem: "Ocorreu um erro ao remover a rede social escolhida.",
              duracao: 3000,
            });
          })
          .finally(() => {
            this.loadingRemove = null;
          });

        return;
      }

      this.redesSociais[index] = {
        rede: item.rede,
        link: "",
        exists: false,
      };
      this.loadingRemove = null;
    },

    /**
     * @description Coloca em modo de edição ou salva o dado. Caso não esteja em modo de edição está função irá colocar para o usuário editar os dados, depois ao salvar novamente o dado será salvo.
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    save(item = Object, index = Number) {
      if (this.isEdit == null) {
        this.isEdit = index;
        Object.assign(this.item, item);

        return;
      }

      this.$validator
        .validate(`res-candidato-profissionais.link-${index}`)
        .then((result) => {
          if (result) {
            this.loadingSave = index;

            const isEdit = this.item.id || this.item.uuid ? true : false;
            const url = isEdit
              ? `/res-candidato-rede-social/${this.item.uuid}`
              : "/res-candidato-rede-social";
            const method = isEdit ? "PUT" : "POST";

            const data = {
              url: url,
              data: {
                ...this.item,
                _method: method,
              },
            };

            this.$store
              .dispatch("resCandidatosRedesSociais/save", data)
              .then((response) => {
                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  cor: "green",
                  mensagem: "Rede social salva com sucesso.",
                  duracao: 3000,
                });

                const selectedItem = isEdit ? this.item : response;

                const redes = [...this.redesSociais];
                redes[index] = selectedItem;

                this.redesSociais = redes;
              })
              .catch(() => {
                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  cor: "red",
                  mensagem: "Ocorreu um erro ao salvar a rede social.",
                  duracao: 3000,
                });
              })
              .finally(() => {
                this.loadingSave = null;
                this.item = {};
                this.isEdit = null;
              });
          }
        });
    },

    /**
     * @description Faz validações e com base nas mesmas define se o botão de remove será desabilitado ou não
     *
     * @param item = Object
     * @param i = Number
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    validateDisabledButtonRemove(item, i) {
      if (item.link == null && this.isEdit == null) {
        return true;
      }
      if (this.isEdit == i) {
        return false;
      }
      if (this.isEdit != null && this.isEdit != i) {
        return true;
      }

      return false;
    },
  },
  computed: {
    redesSociais: {
      get() {
        let redeSociais =
          this.$store.getters["resCandidatosRedesSociais/getRedesSociais"];

        let arrayProcessed = [
          {
            rede: "linkedin",
            link: null,
          },
          {
            rede: "facebook",
            link: null,
          },
          {
            rede: "twitter",
            link: null,
          },
          {
            rede: "github",
            link: null,
          },
        ];

        redeSociais.forEach(function (el) {
          let element = el;
          let index = arrayProcessed.findIndex((e) => e.rede == el.rede);

          switch (el.rede) {
            case "linkedin":
              arrayProcessed.splice(index, 1, element);
              break;
            case "facebook":
              arrayProcessed.splice(index, 1, element);
              break;
            case "twitter":
              arrayProcessed.splice(index, 1, element);
              break;
            case "github":
              arrayProcessed.splice(index, 1, element);
              break;
          }
        });

        return arrayProcessed;
      },
      set(value) {
        this.$store.dispatch(
          "resCandidatosRedesSociais/setRedesSociais",
          value
        );
      },
    },
  },
};
</script>

<style></style>
