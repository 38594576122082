<template>
  <v-row>
    <v-col cols="12">
      <div
        class="d-flex flex-column flex-sm-row justify-space-between align-center"
      >
        <h2>Idiomas</h2>
        <v-btn color="primary" @click="add()">
          <v-icon>mdi-plus</v-icon> Adicionar
        </v-btn>
      </div>
    </v-col>

    <v-col cols="12" v-for="(idioma, i) in idiomas" :key="i">
      <div class="d-flex flex-column flex-md-row align-center my-2">
        <v-row v-if="isEdit == i">
          <!-- Idioma -->
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              v-model.trim="item.descricao"
              label="Idioma"
              placeholder="Digite o idioma."
              outlined
              dense
              data-vv-as="idioma"
              persistent-hint
              :hint="errors.first(`res-candidato-profissionais.descricao-${i}`)"
              :error="
                errors.collect(`res-candidato-profissionais.descricao-${i}`)
                  .length
                  ? true
                  : false
              "
              :hide-details="
                errors.collect(`res-candidato-profissionais.descricao-${i}`)
                  .length
                  ? false
                  : true
              "
              v-validate="'required'"
              data-vv-validate-on="change"
              data-vv-scope="res-candidato-profissionais"
              :ref="`descricao-${i}`"
              :id="`descricao-${i}`"
              :name="`descricao-${i}`"
            ></v-text-field>
          </v-col>
          <!-- experiencia -->
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              v-model="item.experiencia"
              label="Experiência"
              placeholder="Digite o número de meses ou anos que tem de experiência."
              outlined
              dense
              data-vv-as="experiência"
              persistent-hint
              :hint="
                errors.first(`res-candidato-profissionais.experiencia-${i}`)
              "
              :error="
                errors.collect(`res-candidato-profissionais.experiencia-${i}`)
                  .length
                  ? true
                  : false
              "
              :hide-details="
                errors.collect(`res-candidato-profissionais.experiencia-${i}`)
                  .length
                  ? false
                  : true
              "
              v-validate="'required'"
              data-vv-validate-on="change"
              data-vv-scope="res-candidato-profissionais"
              :ref="`experiencia-${i}`"
              :id="`experiencia-${i}`"
              :name="`experiencia-${i}`"
              type="number"
            ></v-text-field>
          </v-col>
          <!-- Tempo -->
          <v-col cols="12" sm="12" md="6">
            <v-select
              v-model="item.tempo"
              :items="tempos"
              item-text="text"
              item-value="value"
              label="Tempo"
              placeholder="Selecione seu tempo de experiência"
              outlined
              dense
              data-vv-as="tempo"
              persistent-hint
              :hint="errors.first(`res-candidato-profissionais.tempo-${i}`)"
              :error="
                errors.collect(`res-candidato-profissionais.tempo-${i}`).length
                  ? true
                  : false
              "
              :hide-details="
                errors.collect(`res-candidato-profissionais.tempo-${i}`).length
                  ? false
                  : true
              "
              v-validate="'required'"
              data-vv-validate-on="change"
              data-vv-scope="res-candidato-profissionais"
              :id="`tempo-${i}`"
              :name="`tempo-${i}`"
            ></v-select>
          </v-col>
          <!-- Proficiência -->
          <v-col cols="12" sm="12" md="6">
            <v-select
              v-model="item.proficiencia"
              :items="proficiencias"
              label="Proficiência"
              placeholder="Selecione sua proficiência"
              outlined
              dense
              data-vv-as="proficiência"
              persistent-hint
              :hint="
                errors.first(`res-candidato-profissionais.proficiencia-${i}`)
              "
              :error="
                errors.collect(`res-candidato-profissionais.proficiencia-${i}`)
                  .length
                  ? true
                  : false
              "
              :hide-details="
                errors.collect(`res-candidato-profissionais.proficiencia-${i}`)
                  .length
                  ? false
                  : true
              "
              v-validate="'required'"
              data-vv-validate-on="change"
              data-vv-scope="res-candidato-profissionais"
              :id="`proficiencia-${i}`"
              :name="`proficiencia-${i}`"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" sm="12" md="12">
            <v-list three-line>
              <v-list-item-title class="font-weight-medium text-h6">
                {{ idioma.descricao }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ idioma.experiencia }}
                {{
                  idioma.tempo == "M"
                    ? idioma.experiencia > 1
                      ? "Meses"
                      : "Mês"
                    : idioma.experiencia > 1
                    ? "Anos"
                    : "Ano"
                }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ proficienciasView[idioma.proficiencia] }}
              </v-list-item-subtitle>
            </v-list>
          </v-col>
        </v-row>
        <v-btn
          small
          :loading="loadingSave && loadingSave == i ? true : false"
          :disabled="isEdit == null ? false : isEdit == i ? false : true"
          min-width="1em"
          class="ml-2 mt-md-0 mt-2"
          color="info"
          @click="save(idioma, i)"
        >
          <v-icon>{{ isEdit == i ? "mdi-check" : "mdi-pencil" }}</v-icon>
        </v-btn>
        <v-btn
          small
          :loading="loadingRemove && loadingRemove == i ? true : false"
          :disabled="isEdit == null ? false : isEdit == i ? false : true"
          min-width="1em"
          class="ml-2 mt-md-0 mt-2"
          color="error"
          @click="remove(idioma, i)"
        >
          <v-icon>{{ isEdit == i ? "mdi-close" : "mdi-delete" }}</v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "IdiomasRepeater",
  inject: ["$validator"],
  data() {
    return {
      tempos: [
        {
          text: "Mês",
          value: "M",
        },
        {
          text: "Ano",
          value: "A",
        },
      ],
      proficiencias: [
        {
          text: "Básico",
          value: "B",
        },
        {
          text: "Intermediário",
          value: "I",
        },
        {
          text: "Avançado",
          value: "A",
        },
      ],

      proficienciasView: {
        B: "Básico",
        I: "Intermediário",
        A: "Avançado",
      },

      loadingRemove: null,
      loadingSave: null,

      // Define se está no modo de edição ou não
      isEdit: null,
      item: {},
    };
  },
  methods: {
    /**
     * @description Adiciona um novo dado no array
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    add() {
      const data = {
        descricao: "",
        experiencia: 0,
        tempo: null,
        proficiencia: null,
      };

      this.idiomas.push(data);
      this.isEdit = this.idiomas.length - 1;
    },

    /**
     * @description Se estiver em modo edição e o usuário desejar cancelar o processo de atualização está função limpará o processo. Caso não esteja em modo de edição o dado será excluído.
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    remove(item = Object, index = Number) {
      if (this.isEdit == index) {
        this.isEdit = null;
        this.item = {};

        return;
      }

      this.loadingRemove = index;

      if (item.id || item.uuid) {
        this.$store
          .dispatch("resCandidatosIdiomas/remove", item.uuid)
          .then(() => {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              cor: "green",
              mensagem: "Idioma removido com sucesso.",
              duracao: 3000,
            });
          })
          .catch(() => {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              cor: "red",
              mensagem: "Ocorreu um erro ao remover o idioma escolhido.",
              duracao: 3000,
            });
          })
          .finally(() => {
            this.idiomas.splice(index, 1);
            this.loadingRemove = null;
          });

        return;
      }

      this.idiomas.splice(index, 1);
      this.loadingRemove = null;
    },

    /**
     * @description Coloca em modo de edição ou salva o dado. Caso não esteja em modo de edição está função irá colocar para o usuário editar os dados, depois ao salvar novamente o dado será salvo.
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    save(item = Object, index = Number) {
      if (this.isEdit == null) {
        this.isEdit = index;
        Object.assign(this.item, item);

        return;
      }

      this.loadingSave = index;

      const isEdit = this.item.id || this.item.uuid ? true : false;
      const url = isEdit
        ? `/res-candidato-idioma/${this.item.uuid}`
        : "/res-candidato-idioma";
      const method = isEdit ? "PUT" : "POST";

      const data = {
        url: url,
        data: {
          ...this.item,
          _method: method,
        },
      };

      this.$store
        .dispatch("resCandidatosIdiomas/save", data)
        .then((response) => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            cor: "green",
            mensagem: "Idioma salvo com sucesso.",
            duracao: 3000,
          });

          isEdit
            ? (this.idiomas[index] = this.item)
            : (this.idiomas[index] = response);
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            cor: "red",
            mensagem: "Ocorreu um erro ao salvar o idioma.",
            duracao: 3000,
          });
        })
        .finally(() => {
          this.loadingSave = null;
          this.item = {};
          this.isEdit = null;
        });
    },
  },
  computed: {
    idiomas: {
      get() {
        return this.$store.getters["resCandidatosIdiomas/getIdiomas"];
      },
      set(value) {
        this.$store.dispatch("resCandidatosIdiomas/setIdiomas", value);
      },
    },
  },
};
</script>

<style></style>
